export const lightMode = {
  // Fonts

  // Colors
  primaryColor: '#fff',
  textColor: '#252525',

  // Sizes

  // Shadows

  // Transitions
};
